import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faUser, faList, faPlus, faHome, faEdit, faTrash, faPhone, faSpinner, faCheck, faShare, faCopy } from '@fortawesome/free-solid-svg-icons';
import logo from './icons/keboon-logo-white.png';

const Input = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const PageWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: white;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377E62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
`;

const ProfileInfo = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const EmailDisplay = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
`;

const ResetPasswordButton = styled.button`
  padding: 10px 20px;
  background-color: #52AE77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #377E62;
  }
`;

const LogoutButton = styled.button`
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #d32f2f;
  }
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;

  &:hover {
    color: #377E62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: #377E62;
`;

const ErrorMessage = styled.p`
  color: #f44336;
  text-align: center;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: #4CAF50;
  text-align: center;
  margin-top: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FeedbackBox = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FeedbackTextarea = styled.textarea`
  width: 95%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
`;

const SubmitFeedbackButton = styled.button`
  padding: 10px 20px;
  background-color: #52AE77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #377E62;
  }
`;

const SaveButton = styled.button`
  padding: 10px 20px;
  background-color: #52AE77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #377E62;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const ShareButton = styled.button`
  padding: 10px 20px;
  background-color: #4267B2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #365899;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ShareModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

function UserProfilePage() {
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [showShareModal, setShowShareModal] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get('/api/user/profile', {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
        });
        setUserEmail(response.data.email);
        setUserName(response.data.name || '');
        setUserMobile(response.data.mobileNumber || '');
      } catch (error) {
        console.error('Error fetching user profile:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, [navigate]);

  const handleSaveProfile = async () => {
    setIsSaving(true);
    setSaveSuccess(false);
    try {
      const response = await axios.put('/api/user/profile', 
        { name: userName, mobileNumber: userMobile },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` } }
      );
      setSuccessMessage('Profile updated successfully');
      setSaveSuccess(true);
      setError('');
      setTimeout(() => setSaveSuccess(false), 3000); // Reset success state after 3 seconds
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
      setSuccessMessage('');
    } finally {
      setIsSaving(false);
    }
  };

  const handleResetPassword = () => {
    // Add logic for handling password reset
    alert('Password reset functionality will be implemented here.');
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmitFeedback = async () => {
    try {
      const response = await axios.post('/api/submit-feedback', 
        { feedback },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` } }
      );
      setFeedback('');
      setSuccessMessage(response.data.message || 'Feedback submitted successfully');
      setError('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setError(error.response?.data?.message || 'Failed to submit feedback. Please try again.');
      setSuccessMessage('');
    }
  };

  const handleShareMyKeboon = async () => {
    setIsSharing(true);
    try {
      const response = await axios.post('/api/user/share-keboon', 
        {},
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` } }
      );
      setShareUrl(response.data.shareUrl);
      setShowShareModal(true);
      setSuccessMessage('Your Keboon has been shared successfully!');
      setError('');
    } catch (error) {
      console.error('Error sharing Keboon:', error);
      setError('Failed to share your Keboon. Please try again.');
      setSuccessMessage('');
    } finally {
      setIsSharing(false);
    }
  };

  const handleCopyShareUrl = async () => {
    setIsCopying(true);
    try {
      await navigator.clipboard.writeText(shareUrl);
      setIsCopied(true);
      setSuccessMessage('Share URL copied to clipboard!');
      setTimeout(() => {
        setIsCopied(false);
        setSuccessMessage('');
      }, 2000); // Reset copied state and success message after 2 seconds
    } catch (err) {
      console.error('Failed to copy: ', err);
      setError('Failed to copy the share URL. Please try again.');
    } finally {
      setIsCopying(false);
    }
  };

  return (
    <PageWrapper>
      <Header>
        <Logo src={logo} alt="Keboon Logo" />
        <Subtitle>User Profile</Subtitle>
      </Header>
      <MainContainer>
        {isLoading ? (
          <LoadingMessage>Loading user profile...</LoadingMessage>
        ) : (
          <>
            <ProfileInfo>
              <EmailDisplay>Email: {userEmail}</EmailDisplay>
              <Input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Name"
              />
              <Input
                type="tel"
                value={userMobile}
                onChange={(e) => setUserMobile(e.target.value)}
                placeholder="Mobile Number"
              />
              <SaveButton onClick={handleSaveProfile} disabled={isSaving}>
                {isSaving ? (
                  <>
                    <ButtonIcon icon={faSpinner} spin /> Saving...
                  </>
                ) : saveSuccess ? (
                  <>
                    <ButtonIcon icon={faCheck} /> Saved!
                  </>
                ) : (
                  'Save Profile'
                )}
              </SaveButton>
              <FeedbackBox>
                <h3>Send Feedback</h3>
                <FeedbackTextarea
                  value={feedback}
                  onChange={handleFeedbackChange}
                  placeholder="Enter your feedback here..."
                />
                <SubmitFeedbackButton onClick={handleSubmitFeedback}>
                  Submit Feedback
                </SubmitFeedbackButton>
              </FeedbackBox>
              <ButtonContainer>
                <ShareButton onClick={handleShareMyKeboon} disabled={isSharing}>
                  {isSharing ? (
                    <>
                      <ButtonIcon icon={faSpinner} spin /> Sharing...
                    </>
                  ) : (
                    <>
                      <ButtonIcon icon={faShare} /> Share My Keboon
                    </>
                  )}
                </ShareButton>
                <ResetPasswordButton onClick={handleResetPassword}>
                  Reset Password
                </ResetPasswordButton>
                <LogoutButton onClick={handleLogout}>
                  Logout
                </LogoutButton>
              </ButtonContainer>
            </ProfileInfo>
            
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
          </>
        )}
      </MainContainer>
      {showShareModal && (
        <>
          <Overlay onClick={() => setShowShareModal(false)} />
          <ShareModal>
            <CloseButton onClick={() => setShowShareModal(false)}>&times;</CloseButton>
            <h3>Share Your Keboon</h3>
            <p>Use this link to share your Keboon:</p>
            <Input type="text" value={shareUrl} readOnly />
            <ShareButton onClick={handleCopyShareUrl} disabled={isCopying}>
              {isCopying ? (
                <>
                  <ButtonIcon icon={faSpinner} spin /> Copying...
                </>
              ) : isCopied ? (
                <>
                  <ButtonIcon icon={faCheck} /> Copied!
                </>
              ) : (
                <>
                  <ButtonIcon icon={faCopy} /> Copy Link
                </>
              )}
            </ShareButton>
          </ShareModal>
        </>
      )}

      <BottomNavigation>
        <NavItem to="/main">
          <NavIcon><FontAwesomeIcon icon={faHome} /></NavIcon>
          Home
        </NavItem>
        <NavItem to="/add-product">
          <NavIcon><FontAwesomeIcon icon={faPlus} /></NavIcon>
          Add Product
        </NavItem>
        <NavItem to="/wallet">
          <NavIcon><FontAwesomeIcon icon={faWallet} /></NavIcon>
          Wallet
        </NavItem>
        <NavItem to="/my-listing">
          <NavIcon><FontAwesomeIcon icon={faList} /></NavIcon>
          My Listing
        </NavItem>
        <NavItem to="/profile">
          <NavIcon><FontAwesomeIcon icon={faUser} /></NavIcon>
          Profile
        </NavItem>
      </BottomNavigation>
    </PageWrapper>
  );
}

export default UserProfilePage;