import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faUser, faList, faPlus, faHome, faUpload } from '@fortawesome/free-solid-svg-icons';
import logo from './icons/keboon-logo-white.png';

const PageWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: white;
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377E62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
`;

const ErrorMessage = styled.p`
  color: #f44336;
  text-align: center;
  margin-bottom: 10px;
`;

const ImageUpload = styled.div`
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #377E62;
  }
`;

const UploadIcon = styled.span`
  color: #377E62;
  font-size: 48px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #377E62;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #377E62;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 7L13 1" stroke="%23377E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 14px auto;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #377E62;
  }
`;

const PriceInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const PriceLabel = styled.label`
  margin-right: 10px;
  color: #333;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const CancelButton = styled(Button)`
  background-color: #f0f0f0;
  color: #333;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const PostButton = styled(Button)`
  background-color: ${props => props.disabled ? '#a0a0a0' : '#377E62'};
  color: white;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  &:hover {
    background-color: ${props => props.disabled ? '#a0a0a0' : '#52AE77'};
  }
`;

const ImageInput = styled.input`
  display: none;
`;

const ImageLabel = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #377E62;
  }
`;

const MapComponent = styled(MapContainer)`
  width: 100%;
  height: 300px;
  margin-bottom: 15px;
  z-index: 1;
`;

const SuccessPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PopupButton = styled(Button)`
  background-color: #377E62;
  color: white;
  margin-top: 15px;

  &:hover {
    background-color: #52AE77;
  }
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;

  &:hover {
    color: #377E62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

const defaultCenter = [3.140853, 101.693207]; // Default center (Kuala Lumpur)

const unitOptions = [
  { value: '100g', label: '100 gram' },
  { value: 'kg', label: 'Kilogram (kg)' },
  { value: 'mL', label: 'Milliliter (mL)' },
  { value: 'L', label: 'Liter (L)' }
];

const locationIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const ModalButton = styled(Button)`
  margin: 10px;
`;

function AddProductPage() {
  const [productName, setProductName] = useState('');
  const [location, setLocation] = useState(null);
  const [locationDetail, setLocationDetail] = useState('');
  const [unitMeasurement, setUnitMeasurement] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [showInsufficientFundsModal, setShowInsufficientFundsModal] = useState(false);
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const [postPrice, setPostPrice] = useState(0.10);
  const [updatedBalance, setUpdatedBalance] = useState(null);

  useEffect(() => {
    getUserLocation();
    fetchWalletBalance();
    fetchPostPrice();
  }, []);

  const fetchWalletBalance = async () => {
    try {
      const response = await axios.get('/api/wallet', {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
      });
      setWalletBalance(response.data.balance);
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
      setError('Failed to fetch wallet balance. Please try again.');
    }
  };

  const fetchPostPrice = async () => {
    try {
      const response = await axios.get('/api/post-price');
      setPostPrice(response.data.price);
    } catch (error) {
      console.error('Error fetching post price:', error);
      setError('Failed to fetch post price. Please try again.');
    }
  };

  const getUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = [position.coords.latitude, position.coords.longitude];
          setLocation(currentLocation);
          setMapCenter(currentLocation);
        },
        (error) => {
          console.error("Error getting location:", error);
          setLocation(defaultCenter);
          setMapCenter(defaultCenter);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      setLocation(defaultCenter);
      setMapCenter(defaultCenter);
    }
  };

  function MapEvents() {
    const map = useMap();
    useMapEvents({
      click(e) {
        setLocation([e.latlng.lat, e.latlng.lng]);
      },
    });

    useEffect(() => {
      if (mapCenter) {
        map.setView(mapCenter, map.getZoom());
      }
    }, [mapCenter]);

    return null;
  }

  const handleMarkerDragEnd = (event) => {
    const marker = event.target;
    const position = marker.getLatLng();
    setLocation([position.lat, position.lng]);
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const MAX_WIDTH = 800;
          const scaleFactor = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scaleFactor;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const compressedBase64 = canvas.toDataURL('image/jpeg', 0.8);
          resolve(compressedBase64);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const compressedBase64 = await compressImage(file);
      setImage(compressedBase64);
      setPreviewUrl(compressedBase64);
    }
  };

  const clearForm = () => {
    setProductName('');
    setLocation(null);
    setLocationDetail('');
    setUnitMeasurement('');
    setPrice('');
    setImage(null);
    setPreviewUrl('');
    setMapCenter(defaultCenter);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    if (!productName || !location || !locationDetail || !unitMeasurement || !price || !image) {
      setError('All fields are mandatory. Please fill in all the information.');
      setIsSubmitting(false);
      return;
    }

    if (walletBalance < postPrice) {
      setShowInsufficientFundsModal(true);
      setIsSubmitting(false);
      return;
    }

    const productData = {
      name: productName,
      price: parseFloat(price),
      unit: unitMeasurement,
      location: {
        lat: location[0],
        lon: location[1]
      },
      locationDetail: locationDetail,
      image: image
    };

    try {
      const response = await axios.post('/api/products',
        productData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        console.log('Product added:', response.data);
        setUpdatedBalance(response.data.newBalance);
        setShowSuccessPopup(true);
      } catch (error) {
        console.error('Error adding product:', error);
        setError(`Error adding product: ${error.response?.data?.message || error.message}`);
      } finally {
        setIsSubmitting(false);
      }
    };
  
    const handleCancel = () => {
      navigate('/main');
    };
  
    const handleSuccessPopupClose = () => {
      setShowSuccessPopup(false);
      clearForm();
      getUserLocation();
    };
  
    const handleTopUp = () => {
      navigate('/wallet');
    };
  
    const handleCancelPost = () => {
      setShowInsufficientFundsModal(false);
    };
  
    return (
      <PageWrapper>
        <Header>
        <Logo src={logo} alt="Keboon Logo" />
          <Subtitle>Add Product</Subtitle>
        </Header>
        <Container>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <p>Posting a product costs RM{postPrice.toFixed(2)}</p>
          <p>Your current balance: RM{walletBalance.toFixed(2)}</p>
          <form onSubmit={handleSubmit}>
            <ImageLabel>
              {previewUrl ? (
                <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '4px' }} />
              ) : (
                <UploadIcon><FontAwesomeIcon icon={faUpload} /></UploadIcon>
              )}
              <ImageInput
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </ImageLabel>
            <Input
              placeholder="Produce Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
            <MapComponent center={mapCenter} zoom={13} ref={mapRef}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <MapEvents />
              {location && (
                <Marker 
                  position={location} 
                  icon={locationIcon} 
                  draggable={true}
                  eventHandlers={{
                    dragend: handleMarkerDragEnd,
                  }}
                >
                  <Popup>Product location</Popup>
                </Marker>
              )}
            </MapComponent>
            <TextArea
              placeholder="Location Detail"
              value={locationDetail}
              onChange={(e) => setLocationDetail(e.target.value)}
              required
            />
            <Select
              value={unitMeasurement}
              onChange={(e) => setUnitMeasurement(e.target.value)}
              required
            >
              <option value="">Unit Measurement</option>
              {unitOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <PriceInput>
              <PriceLabel>Price/ Unit :</PriceLabel>
              <Input
                type="number"
                placeholder="RM"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </PriceInput>
            <p style={{ fontSize: '14px', color: '#666', marginBottom: '20px' }}>Ensure all information is accurate to avoid rejection by the system admin.</p>
            <ButtonContainer>
              <CancelButton type="button" onClick={handleCancel}>Cancel</CancelButton>
              <PostButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Posting...' : 'Post'}
              </PostButton>
            </ButtonContainer>
          </form>
          {showSuccessPopup && (
            <SuccessPopup>
              <PopupContent>
                <h2>Post Successful!</h2>
                <p>Your product has been submitted successfully.</p>
                <p>RM{postPrice.toFixed(2)} has been deducted from your wallet.</p>
                <p>Your new balance is: RM{updatedBalance.toFixed(2)}</p>
                <p>Please wait for admin approval. After approval, you will see the product on the main page.</p>
                <PopupButton onClick={handleSuccessPopupClose}>OK</PopupButton>
              </PopupContent>
            </SuccessPopup>
          )}
          {showInsufficientFundsModal && (
            <ModalOverlay>
              <ModalContent>
                <h2>Insufficient Funds</h2>
                <p>You need RM{postPrice.toFixed(2)} to post a product. Your current balance is RM{walletBalance.toFixed(2)}.</p>
                <ModalButton onClick={handleTopUp}>Top Up</ModalButton>
                <ModalButton onClick={handleCancelPost}>Cancel</ModalButton>
              </ModalContent>
            </ModalOverlay>
          )}
        </Container>
        <BottomNavigation>
          <NavItem to="/main">
            <NavIcon><FontAwesomeIcon icon={faHome} /></NavIcon>
            Home
          </NavItem>
          <NavItem to="/add-product">
            <NavIcon><FontAwesomeIcon icon={faPlus} /></NavIcon>
            Add Product
          </NavItem>
          <NavItem to="/wallet">
            <NavIcon><FontAwesomeIcon icon={faWallet} /></NavIcon>
            Wallet
          </NavItem>
          <NavItem to="/my-listing">
            <NavIcon><FontAwesomeIcon icon={faList} /></NavIcon>
            My Listing
          </NavItem>
          <NavItem to="/profile">
            <NavIcon><FontAwesomeIcon icon={faUser} /></NavIcon>
            Profile
          </NavItem>
        </BottomNavigation>
      </PageWrapper>
    );
  }
  
  export default AddProductPage;