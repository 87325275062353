import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faUser,
  faList,
  faPlus,
  faHome,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import logo from './icons/keboon-logo-white.png';

const WalletScreen = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [topUpCode, setTopUpCode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();

  const TRANSACTIONS_PER_PAGE = 10;

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const { data } = await axios.get('/api/config');
        const publishableKey = data.publishableKey;
        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        console.error('Error fetching Stripe config:', error);
        setError('Failed to initialize payment system. Please try again later.');
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('payment_status') === 'success') {
      let attempts = 0;
      const maxAttempts = 10;

      const pollInterval = setInterval(() => {
        fetchWalletDetails();
        attempts++;

        if (attempts >= maxAttempts) {
          clearInterval(pollInterval);
        }
      }, 2000);

      return () => clearInterval(pollInterval);
    } else {
      fetchWalletDetails();
    }
  }, []);

  const fetchWalletDetails = async () => {
    try {
      setIsLoading(true);
      const timestamp = new Date().getTime();
      const response = await axios.get(`/api/wallet?_t=${timestamp}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setBalance(response.data.balance);
      
      const sortedTransactions = response.data.transactions.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      setTransactions(sortedTransactions);
      setTotalPages(Math.ceil(sortedTransactions.length / TRANSACTIONS_PER_PAGE));
      
      setError(null);
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      setError("Failed to fetch wallet details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleTopUp = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage("");
    setIsLoading(true);
  
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      setError('Please enter a valid amount.');
      setIsLoading(false);
      return;
    }
  
    try {
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }
  
      const response = await axios.post('/api/create-checkout-session',
        { amount: parseFloat(amount) },
        { 
          headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` },
        }
      );
  
      if (!response.data || !response.data.sessionId) {
        throw new Error(`Invalid response from server: ${JSON.stringify(response.data)}`);
      }
  
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });
  
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error details:', error);
      setError(`Failed to initiate top-up: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTopUpCodeChange = (e) => {
    setTopUpCode(e.target.value);
  };

  const handleApplyTopUpCode = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage("");
    setIsLoading(true);

    try {
      const response = await axios.post('/api/apply-topup-code', 
        { code: topUpCode },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` } }
      );

      setSuccessMessage(response.data.message);
      setBalance(response.data.newBalance);
      setTopUpCode("");
      fetchWalletDetails();
    } catch (error) {
      console.error('Error applying top-up code:', error);
      setError(error.response?.data?.message || 'Failed to apply top-up code');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getCurrentPageTransactions = () => {
    const startIndex = (currentPage - 1) * TRANSACTIONS_PER_PAGE;
    const endIndex = startIndex + TRANSACTIONS_PER_PAGE;
    return transactions.slice(startIndex, endIndex);
  };

  return (
    <PageWrapper>
      <Header>
      <Logo src={logo} alt="Keboon Logo" />
        <Subtitle>Wallet</Subtitle>
      </Header>
      <MainContainer>
        <BalanceDisplay>
          Current Balance: RM {balance.toFixed(2)}
        </BalanceDisplay>
        <Form onSubmit={handleTopUp}>
          <InputWrapper>
            <AmountInput
              type="number"
              value={amount}
              onChange={handleAmountChange}
              placeholder="Enter top-up amount"
              step="0.01"
              min="0"
              id="topUpAmount"
              name="topUpAmount"
              autoComplete="off"
            />
          </InputWrapper>
          <TopUpButton type="submit" disabled={isLoading}>
            {isLoading ? "Processing..." : "Top Up"}
          </TopUpButton>
        </Form>
        
        <Form onSubmit={handleApplyTopUpCode}>
          <InputWrapper>
            <AmountInput
              type="text"
              value={topUpCode}
              onChange={handleTopUpCodeChange}
              placeholder="Enter top-up code"
              id="topUpCode"
              name="topUpCode"
            />
          </InputWrapper>
          <TopUpButton type="submit" disabled={isLoading}>
            {isLoading ? "Processing..." : "Apply Code"}
          </TopUpButton>
        </Form>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        
        <TransactionHistory>
          <h3>Transaction History</h3>
          {getCurrentPageTransactions().length > 0 ? (
            getCurrentPageTransactions().map((transaction, index) => (
              <Transaction key={index}>
                <TransactionDescription>{transaction.description}</TransactionDescription>
                <TransactionAmount positive={transaction.amount > 0}>
                  {transaction.amount > 0 ? "+" : "-"}RM{" "}
                  {Math.abs(transaction.amount).toFixed(2)}
                </TransactionAmount>
                <TransactionDate>{new Date(transaction.date).toLocaleString()}</TransactionDate>
              </Transaction>
            ))
          ) : (
            <NoTransactions>No transactions yet.</NoTransactions>
          )}
          {transactions.length > TRANSACTIONS_PER_PAGE && (
            <Pagination>
              <PageButton 
                onClick={() => handlePageChange(currentPage - 1)} 
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </PageButton>
              <PageInfo>Page {currentPage} of {totalPages}</PageInfo>
              <PageButton 
                onClick={() => handlePageChange(currentPage + 1)} 
                disabled={currentPage === totalPages}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </PageButton>
            </Pagination>
          )}
        </TransactionHistory>
        <BottomNavigation>
          <NavItem to="/main">
            <NavIcon>
              <FontAwesomeIcon icon={faHome} />
            </NavIcon>
            Home
          </NavItem>
          <NavItem to="/add-product">
            <NavIcon>
              <FontAwesomeIcon icon={faPlus} />
            </NavIcon>
            Add Product
          </NavItem>
          <NavItem to="/wallet">
            <NavIcon>
              <FontAwesomeIcon icon={faWallet} />
            </NavIcon>
            Wallet
          </NavItem>
          <NavItem to="/my-listing">
            <NavIcon>
              <FontAwesomeIcon icon={faList} />
            </NavIcon>
            My Listing
          </NavItem>
          <NavItem to="/profile">
            <NavIcon>
              <FontAwesomeIcon icon={faUser} />
            </NavIcon>
            Profile
          </NavItem>
        </BottomNavigation>
      </MainContainer>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: white;
  min-height: 100vh;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377e62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
`;

const MainContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const BalanceDisplay = styled.h2`
  color: #4caf50;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const AmountInput = styled.input`
  width: 95%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TopUpButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: #4caf50;
  margin-top: 10px;
`;

const TransactionHistory = styled.div`
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
`;

const Transaction = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #eee;
`;

const TransactionDescription = styled.span`
  text-align: left;
  font-weight: bold;
`;

const TransactionAmount = styled.span`
  text-align: right;
  color: ${props => props.positive ? '#4caf50' : '#f44336'};
  font-weight: bold;
`;

const TransactionDate = styled.span`
  font-size: 0.8em;
  color: #666;
  text-align: right;
`;

const NoTransactions = styled.p`
  color: #666;
  font-style: italic;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background-color: #377e62;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  margin: 0 10px;
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;

  &:hover {
    color: #377e62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

export default WalletScreen;