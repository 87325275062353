import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faMapMarkerAlt, faDollarSign, faHeart, faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import logo from './icons/keboon-logo-white.png';

const PageWrapper = styled.div`
  padding-top: 80px;
  background-color: white;
  min-height: 100vh;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377E62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
`;

const MainContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  color: #377E62;
  margin-bottom: 30px;
`;

const SearchBarWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
`;

const SearchIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #377E62;
`;

const MapComponent = styled(MapContainer)`
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DateGroup = styled.div`
  margin-bottom: 20px;
`;

const DateHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
  color: #377E62;
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h3`
  margin: 0 0 5px 0;
  color: #333;
`;

const ProductPrice = styled.p`
  margin: 0 0 5px 0;
  font-weight: bold;
  color: #52AE77;
`;

const LocationLink = styled.p`
  margin: 0 0 5px 0;
  color: #377E62;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const InterestedButton = styled.button`
  padding: 5px 10px;
  background-color: #52AE77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #377E62;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SpinnerIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const DistanceTag = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  color: #333;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
`;

const ModalInput = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: #52AE77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: ${props => props.active ? '#377E62' : 'white'};
  color: ${props => props.active ? 'white' : '#333'};
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${props => props.active ? '#377E62' : '#f0f0f0'};
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: #377E62;
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 18px;
  color: #ff6b6b;
  margin-top: 50px;
`;

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const productIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const userIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

function SharedKeboonPage() {
  const { shareId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userName, setUserName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [interestedProductId, setInterestedProductId] = useState(null);
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [isInterestLoading, setIsInterestLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [mapCenter, setMapCenter] = useState([3.139003, 101.686855]); // Default center (Kuala Lumpur)
  const [userLocation, setUserLocation] = useState(null);
  const productsPerPage = 5;

  useEffect(() => {
    const fetchSharedProducts = async () => {
      try {
        const response = await axios.get(`/api/shared-keboon/${shareId}`);
        console.log('API Response:', response.data);

        const { products, userName } = response.data;
        const sortedProducts = products.sort((a, b) => new Date(b.date) - new Date(a.date));

        setProducts(sortedProducts);
        setUserName(userName || 'Keboon User');
        
        if (sortedProducts.length > 0 && sortedProducts[0].location) {
          setMapCenter([sortedProducts[0].location.lat, sortedProducts[0].location.lon]);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching shared Keboon products:', error);
        setError(error.response?.data?.message || 'Failed to load shared products');
        setLoading(false);
      }
    };

    fetchSharedProducts();
  }, [shareId]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLoc = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          setUserLocation(userLoc);
          setMapCenter([userLoc.latitude, userLoc.longitude]);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c; // Distance in km
    return distance;
  };

  const handleInterested = (productId) => {
    setInterestedProductId(productId);
    setShowModal(true);
  };

  const handleSubmitInterest = async () => {
    if (!name.trim() || !mobileNumber.trim()) {
      alert('Please enter both your name and mobile number.');
      return;
    }
  
    const mobileRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (!mobileRegex.test(mobileNumber)) {
      alert('Please enter a valid mobile number.');
      return;
    }
  
    setIsInterestLoading(true);
    try {
      const payload = { name: name.trim(), mobileNumber: mobileNumber.trim() };
      console.log('Sending interest payload:', payload);
  
      const response = await axios.post(`/api/products/${interestedProductId}/interest`, payload);
      
      console.log('Interest submission response:', response.data);
  
      alert(response.data.message || 'You have expressed interest in this product. The seller will be notified.');
      setShowModal(false);
      setName('');
      setMobileNumber('');
    } catch (error) {
      console.error('Error expressing interest:', error);
      if (error.response && error.response.data) {
        alert(error.response.data.message || 'Failed to express interest. Please try again.');
      } else {
        alert('An error occurred while sending your request. Please try again.');
      }
    } finally {
      setIsInterestLoading(false);
    }
  };

  const handleLocationClick = (lat, lon) => {
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  };

  const filteredProducts = products
    .filter(product => product.name.toLowerCase().includes(search.toLowerCase()))
    .map(product => ({
      ...product,
      distance: userLocation && product.location
        ? calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            product.location.lat,
            product.location.lon
          )
        : Infinity
    }));

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const pageCount = Math.ceil(filteredProducts.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const groupProductsByDate = (products) => {
    const groups = {};
    products.forEach(product => {
      const date = new Date(product.date).toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(product);
    });
    return groups;
  };

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const groupedProducts = groupProductsByDate(currentProducts);

  function ChangeMapView({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
  }

  if (loading) {
    return (
      <PageWrapper>
        <LoadingMessage>Loading shared products...</LoadingMessage>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <ErrorMessage>{error}</ErrorMessage>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header>
      <Logo src={logo} alt="Keboon Logo" />
      </Header>
      <MainContainer>
        <Title>Welcome to {userName}'s Keboon!</Title>
        <SearchBarWrapper>
          <SearchBar
            type="text"
            placeholder="Search products"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchIcon><FontAwesomeIcon icon={faSearch} /></SearchIcon>
        </SearchBarWrapper>
        <MapComponent center={mapCenter} zoom={13}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <ChangeMapView center={mapCenter} />
          {userLocation && (
            <Marker
              position={[userLocation.latitude, userLocation.longitude]}
              icon={userIcon}
            >
              <Popup>You are here</Popup>
            </Marker>
          )}
          {filteredProducts.map((product) => (
            product.location && (
              <Marker
                key={product._id}
                position={[product.location.lat, product.location.lon]}
                icon={productIcon}
              >
                <Popup>
                  <strong>{product.name}</strong><br />
                  Price: RM{product.price}/{product.unit}<br />
                  Distance: {product.distance.toFixed(2)} km
                </Popup>
              </Marker>
            )
          ))}
        </MapComponent>
        {Object.entries(groupedProducts).map(([date, dateProducts]) => (
          <DateGroup key={date}>
            <DateHeader>{formatDate(date)}</DateHeader>
            {dateProducts.map(product => (
              <ProductCard key={product._id}>
                <ProductImage src={product.image} alt={product.name} />
                <ProductInfo>
                  <ProductName>{product.name}</ProductName>
                  <ProductPrice>RM{product.price}/{product.unit}</ProductPrice>
                  <LocationLink onClick={() => handleLocationClick(product.location.lat, product.location.lon)}>
                    {product.locationDetail}
                  </LocationLink>
                  <InterestedButton 
                    onClick={() => handleInterested(product._id)}
                    disabled={isInterestLoading && interestedProductId === product._id}
                  >
                    {isInterestLoading && interestedProductId === product._id ? (
                      <>
                        <SpinnerIcon icon={faSpinner} spin />
                        Loading...
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faHeart} /> Interested
                      </>
                    )}
                  </InterestedButton>
                </ProductInfo>
                {product.distance !== Infinity && (
                  <DistanceTag>{product.distance.toFixed(2)} km</DistanceTag>
                )}
              </ProductCard>
            ))}
          </DateGroup>
        ))}
        {currentProducts.length === 0 && (
          <ErrorMessage>No products available in this shared Keboon.</ErrorMessage>
        )}
        <PaginationContainer>
          {[...Array(pageCount)].map((_, i) => (
            <PageButton
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              active={currentPage === i + 1}
            >
              {i + 1}
            </PageButton>
          ))}
        </PaginationContainer>
      </MainContainer>

      {showModal && (
        <Modal>
          <ModalContent>
            <h3>Express Interest</h3>
            <ModalInput
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <ModalInput
              type="tel"
              placeholder="Your Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            <ModalButton onClick={handleSubmitInterest} disabled={isInterestLoading}>
              {isInterestLoading ? 'Submitting...' : 'Submit'}
            </ModalButton>
            <ModalButton onClick={() => setShowModal(false)}>Cancel</ModalButton>
          </ModalContent>
        </Modal>
      )}
    </PageWrapper>
  );
}

export default SharedKeboonPage;