import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHeart, faSpinner } from '@fortawesome/free-solid-svg-icons';
import logo from './icons/keboon-logo-white.png';


const InterestedButton = styled.button`
  padding: 5px 10px;
  background-color: #52AE77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #377E62;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SpinnerIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
`;

const ModalInput = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: #52AE77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
`;

const PageWrapper = styled.div`
  padding-top: 80px;
  background-color: white;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377E62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: left;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const SearchBarWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
`;

const SearchIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #377E62;
`;

const MapComponent = styled(MapContainer)`
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DateGroup = styled.div`
  margin-bottom: 20px;
`;

const DateHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
  color: #377E62;
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h3`
  margin: 0 0 5px 0;
  color: #333;
`;

const ProductPrice = styled.p`
  margin: 0 0 5px 0;
  font-weight: bold;
  color: #52AE77;
`;

const LocationLink = styled.p`
  margin: 0 0 5px 0;
  color: #377E62;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const DistanceTag = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  color: #333;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: ${props => props.active ? '#377E62' : 'white'};
  color: ${props => props.active ? 'white' : '#333'};
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${props => props.active ? '#377E62' : '#f0f0f0'};
  }
`;

const SortToggle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const SortButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #377E62;
  background-color: ${props => props.active ? '#377E62' : 'white'};
  color: ${props => props.active ? 'white' : '#377E62'};
  cursor: pointer;
  transition: all 0.3s ease;
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  &:hover {
    background-color: ${props => props.active ? '#52AE77' : '#f0f0f0'};
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: #377E62;
`;

const SoldOutTag = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #f44336;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
`;

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Custom icons for user and product locations
const userIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const productIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

function MainAppPage() {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [apiUrl] = useState('https://app.keboon.net/api');
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;
  const [mapCenter, setMapCenter] = useState([3.139003, 101.686855]); // Default center (Kuala Lumpur)
  const [userLocation, setUserLocation] = useState(null);
  const [sortBy, setSortBy] = useState('distance'); // 'distance' or 'date'
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [interestedProductId, setInterestedProductId] = useState(null);
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [isInterestLoading, setIsInterestLoading] = useState(false);

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/products`);
      setProducts(response.data);
      if (userLocation) {
        setMapCenter([userLocation.latitude, userLocation.longitude]);
      } else if (response.data.length > 0 && response.data[0].location) {
        setMapCenter([response.data[0].location.lat, response.data[0].location.lon]);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
    }
  }, [apiUrl, userLocation]);

  useEffect(() => {
    fetchProducts();
    const intervalId = setInterval(fetchProducts, 1800000);
    return () => clearInterval(intervalId);
  }, [fetchProducts]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLoc = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          setUserLocation(userLoc);
          setMapCenter([userLoc.latitude, userLoc.longitude]);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c; // Distance in km
    return distance;
  };

  const filteredAndSortedProducts = products
    .filter(product => product.name.toLowerCase().includes(search.toLowerCase()))
    .map(product => ({
      ...product,
      distance: userLocation && product.location
        ? calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            product.location.lat,
            product.location.lon
          )
        : Infinity
    }))
    .sort((a, b) => {
      if (sortBy === 'distance') {
        return a.distance - b.distance;
      } else {
        return new Date(b.date) - new Date(a.date);
      }
    });

  const handleLocationClick = (lat, lon) => {
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  };

  const handleInterested = (productId) => {
    setInterestedProductId(productId);
    setShowModal(true);
  };
  
  const handleSubmitInterest = async () => {
    if (!name.trim() || !mobileNumber.trim()) {
      alert('Please enter both your name and mobile number.');
      return;
    }
  
    const mobileRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (!mobileRegex.test(mobileNumber)) {
      alert('Please enter a valid mobile number.');
      return;
    }
  
    setIsInterestLoading(true);
    try {
      const payload = { name: name.trim(), mobileNumber: mobileNumber.trim() };
      console.log('Sending interest payload:', payload);
  
      const response = await axios.post(`${apiUrl}/products/${interestedProductId}/interest`, payload);
      
      console.log('Interest submission response:', response.data);
  
      alert(response.data.message || 'You have expressed interest in this product. The seller will be notified.');
      setShowModal(false);
      setName('');
      setMobileNumber('');
    } catch (error) {
      console.error('Error expressing interest:', error);
      if (error.response && error.response.data) {
        alert(error.response.data.message || 'Failed to express interest. Please try again.');
      } else {
        alert('An error occurred while sending your request. Please try again.');
      }
    } finally {
      setIsInterestLoading(false);
    }
  };
  
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredAndSortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const groupProductsByDate = (products) => {
    const groups = {};
    products.forEach(product => {
      const date = new Date(product.date).toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(product);
    });
    return groups;
  };

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const groupedProducts = groupProductsByDate(currentProducts);

  const pageCount = Math.ceil(filteredAndSortedProducts.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function ChangeMapView({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
  }

  return (
    <PageWrapper>
      <Header>
      <Logo src={logo} alt="Keboon Logo" />
        <NavLinks>
          <NavLink href="/login">Sign In</NavLink>
          <NavLink href="/signup">Sign Up</NavLink>
        </NavLinks>
      </Header>
      <MainContainer>
        <SearchBarWrapper>
          <SearchBar
            type="text"
            placeholder="Search products"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchIcon><FontAwesomeIcon icon={faSearch} /></SearchIcon>
        </SearchBarWrapper>
        <SortToggle>
          <SortButton 
            active={sortBy === 'distance'} 
            onClick={() => setSortBy('distance')}
          >
            Sort by Distance
          </SortButton>
          <SortButton 
            active={sortBy === 'date'} 
            onClick={() => setSortBy('date')}
          >
            Sort by Date
          </SortButton>
        </SortToggle>
        <MapComponent center={mapCenter} zoom={13}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <ChangeMapView center={mapCenter} />
          {userLocation && (
            <Marker
              position={[userLocation.latitude, userLocation.longitude]}
              icon={userIcon}
            >
              <Popup>You are here</Popup>
            </Marker>
          )}
          {filteredAndSortedProducts.map((product) => (
            !product.soldOut && product.location && (
              <Marker
                key={product._id}
                position={[product.location.lat, product.location.lon]}
                icon={productIcon}
              >
                <Popup>
                  <strong>{product.name}</strong><br />
                  Price: RM{product.price}/{product.unit}<br />
                  Distance: {product.distance.toFixed(2)} km
                </Popup>
              </Marker>
            )
          ))}
        </MapComponent>
        
        {isLoading ? (
          <LoadingMessage>Loading products...</LoadingMessage>
        ) : (
          Object.entries(groupedProducts).map(([date, dateProducts]) => (
            <DateGroup key={date}>
              <DateHeader>{formatDate(date)}</DateHeader>
              {dateProducts.map(product => (
                <ProductCard key={product._id}>
                  <ProductImage src={product.image} alt={product.name} />
                  <ProductInfo>
                    <ProductName>{product.name}</ProductName>
                    <ProductPrice>RM{product.price}/{product.unit}</ProductPrice>
                    {!product.soldOut && (
                      <>
                        <LocationLink onClick={() => handleLocationClick(product.location.lat, product.location.lon)}>
                          {product.locationDetail}
                        </LocationLink>
                        <InterestedButton 
                          onClick={() => handleInterested(product._id)}
                          disabled={isInterestLoading}
                        >
                          {isInterestLoading ? (
                            <>
                              <SpinnerIcon icon={faSpinner} spin />
                              Loading...
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faHeart} /> Interested
                            </>
                          )}
                        </InterestedButton>
                      </>
                    )}
                    {product.distance !== Infinity && (
                      <DistanceTag>{product.distance.toFixed(2)} km</DistanceTag>
                    )}
                  </ProductInfo>
                  {product.soldOut && <SoldOutTag>Sold Out</SoldOutTag>}
                </ProductCard>
              ))}
            </DateGroup>
          ))
        )}
        
        {showModal && (
          <Modal>
            <ModalContent>
              <h3>Express Interest</h3>
              <ModalInput
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <ModalInput
                type="tel"
                placeholder="Your Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              <ModalButton onClick={handleSubmitInterest} disabled={isInterestLoading}>
                {isInterestLoading ? 'Submitting...' : 'Submit'}
              </ModalButton>
              <ModalButton onClick={() => setShowModal(false)}>Cancel</ModalButton>
            </ModalContent>
          </Modal>
        )}
        
        <PaginationContainer>
          {[...Array(pageCount)].map((_, i) => (
            <PageButton
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              active={currentPage === i + 1}
            >
              {i + 1}
            </PageButton>
          ))}
        </PaginationContainer>
      </MainContainer>
    </PageWrapper>
  );
}  

export default MainAppPage;