import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  color: #377E62;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: ${props => props.active ? '#377E62' : '#f0f0f0'};
  color: ${props => props.active ? 'white' : 'black'};
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${props => props.active ? '#2c6a52' : '#e0e0e0'};
  }
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  gap: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const ProductImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h3`
  margin: 0 0 10px 0;
  color: #377E62;
`;

const ProductPrice = styled.p`
  margin: 0 0 10px 0;
  font-weight: bold;
`;

const LocationLink = styled.p`
  color: #377E62;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ProductContact = styled.a`
  color: #377E62;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const Button = styled.button`
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const ApproveButton = styled(Button)`
  background-color: #4CAF50;
  color: white;
  &:hover {
    background-color: #45a049;
  }
`;

const RejectButton = styled(Button)`
  background-color: #f44336;
  color: white;
  &:hover {
    background-color: #da190b;
  }
`;

const LogoutButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #d32f2f;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #f2f2f2;
  border-bottom: 2px solid #ddd;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`;

const ErrorMessage = styled.p`
  color: #f44336;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: #4CAF50;
  margin-top: 10px;
`;

function AdminDashboard() {
  const [activeTab, setActiveTab] = useState('products');
  const [pendingProducts, setPendingProducts] = useState([]);
  const [topUpCodes, setTopUpCodes] = useState([]);
  const [newTopUpCode, setNewTopUpCode] = useState({
    code: '',
    validFrom: '',
    validTo: '',
    entitledUsers: '',
    usageLimit: '',
    amount: '',
    isPublic: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [postPrice, setPostPrice] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchPendingProducts();
    fetchTopUpCodes();
    fetchPostPrice();
  }, []);

  const fetchPendingProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('/api/admin/pending-products', {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
      });
      setPendingProducts(response.data.products);
    } catch (error) {
      console.error('Error fetching pending products:', error);
      setError('Failed to fetch pending products. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTopUpCodes = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('/api/admin/topup-codes', {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
      });
      setTopUpCodes(response.data);
    } catch (error) {
      console.error('Error fetching top-up codes:', error);
      setError('Failed to fetch top-up codes. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPostPrice = async () => {
    try {
      const response = await axios.get('/api/post-price');
      setPostPrice(response.data.price.toFixed(2));
    } catch (error) {
      console.error('Error fetching post price:', error);
      setError('Failed to fetch post price. Please try again.');
    }
  };

  const handleApprove = async (productId) => {
    try {
      await axios.post(`/api/admin/approve-product/${productId}`, {}, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
      });
      fetchPendingProducts();
    } catch (error) {
      console.error('Error approving product:', error);
      setError('Failed to approve product. Please try again.');
    }
  };

  const handleReject = async (productId) => {
    try {
      await axios.post(`/api/admin/reject-product/${productId}`, {}, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
      });
      fetchPendingProducts();
    } catch (error) {
      console.error('Error rejecting product:', error);
      setError('Failed to reject product. Please try again.');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('userEmail');
    navigate('/login');
  };

  const handleLocationClick = (lat, lon) => {
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewTopUpCode(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmitTopUpCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccessMessage('');

    try {
      const codeData = {
        ...newTopUpCode,
        entitledUsers: newTopUpCode.isPublic ? [] : newTopUpCode.entitledUsers.split(',').map(email => email.trim())
      };
      console.log('Sending top-up code data:', codeData);
      const response = await axios.post('/api/admin/topup-code', codeData, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
      });
      console.log('Server response:', response.data);
      setSuccessMessage('Top-up code created successfully!');
      setNewTopUpCode({
        code: '',
        validFrom: '',
        validTo: '',
        entitledUsers: '',
        usageLimit: '',
        amount: '',
        isPublic: false
      });
      fetchTopUpCodes();
    } catch (error) {
      console.error('Error creating top-up code:', error.response ? error.response.data : error);
      setError(error.response && error.response.data.message 
        ? error.response.data.message 
        : 'Failed to create top-up code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTopUpCode = async (codeId) => {
    if (window.confirm('Are you sure you want to delete this top-up code?')) {
      try {
        await axios.delete(`/api/admin/topup-code/${codeId}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
        });
        setSuccessMessage('Top-up code deleted successfully');
        fetchTopUpCodes();
      } catch (error) {
        console.error('Error deleting top-up code:', error);
        setError('Failed to delete top-up code. Please try again.');
      }
    }
  };

  const handlePostPriceChange = (e) => {
    setPostPrice(e.target.value);
  };

  const handleUpdatePostPrice = async () => {
    try {
      await axios.post('/api/update-post-price', { price: parseFloat(postPrice) }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
      });
      setSuccessMessage('Post price updated successfully');
    } catch (error) {
      console.error('Error updating post price:', error);
      setError('Failed to update post price. Please try again.');
    }
  };

  return (
    <Container>
      <Title>Admin Dashboard</Title>
      <LogoutButton onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} /> Logout
      </LogoutButton>

      <TabContainer>
        <Tab active={activeTab === 'products'} onClick={() => setActiveTab('products')}>
          Pending Products
        </Tab>
        <Tab active={activeTab === 'topUpCodes'} onClick={() => setActiveTab('topUpCodes')}>
          Top-up Codes
        </Tab>
        <Tab active={activeTab === 'postPrice'} onClick={() => setActiveTab('postPrice')}>
          Post Price
        </Tab>
      </TabContainer>

      {activeTab === 'products' && (
        <>
          <h2>Pending Products</h2>
          {isLoading ? (
            <p>Loading pending products...</p>
          ) : pendingProducts.length > 0 ? (
            pendingProducts.map((product) => (
              <ProductCard key={product._id}>
                <ProductImage src={product.image} alt={product.name} />
                <ProductInfo>
                  <ProductName>{product.name}</ProductName>
                  <ProductPrice>RM{product.price}/{product.unit}</ProductPrice>
                  <LocationLink onClick={() => handleLocationClick(product.location.lat, product.location.lon)}>
                    {product.locationDetail}
                  </LocationLink>
                  <ProductContact href={`tel:${product.contact}`}>{product.contact}</ProductContact>
                  <ButtonGroup>
                    <ApproveButton onClick={() => handleApprove(product._id)}>Approve</ApproveButton>
                    <RejectButton onClick={() => handleReject(product._id)}>Reject</RejectButton>
                  </ButtonGroup>
                </ProductInfo>
              </ProductCard>
            ))
          ) : (
            <p>No pending products found.</p>
          )}
        </>
      )}

      {activeTab === 'topUpCodes' && (
        <>
          <h2>Create Top-up Code</h2>
          <Form onSubmit={handleSubmitTopUpCode}>
            <Input
              name="code"
              value={newTopUpCode.code}
              onChange={handleInputChange}
              placeholder="Code"
              required
            />
            <Input
              name="validFrom"
              type="datetime-local"
              value={newTopUpCode.validFrom}
              onChange={handleInputChange}
              required
            />
            <Input
              name="validTo"
              type="datetime-local"
              value={newTopUpCode.validTo}
              onChange={handleInputChange}
              required
            />
            <Input
              name="usageLimit"
              type="number"
              value={newTopUpCode.usageLimit}
              onChange={handleInputChange}
              placeholder="Usage Limit"
              required
            />
            <Input
              name="amount"
              type="number"
              value={newTopUpCode.amount}
              onChange={handleInputChange}
              placeholder="Amount"
              required
            />
            <CheckboxLabel>
              <input
                type="checkbox"
                name="isPublic"
                checked={newTopUpCode.isPublic}
                onChange={handleInputChange}
              />
              Public Code (available to all users)
            </CheckboxLabel>
            {!newTopUpCode.isPublic && (
              <Input
                name="entitledUsers"
                value={newTopUpCode.entitledUsers}
                onChange={handleInputChange}
                placeholder="Entitled User Emails (comma-separated)"
              />
            )}
            <Button type="submit" disabled={isLoading}>
              {isLoading ? 'Creating...' : 'Create Top-up Code'}
            </Button>
          </Form>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

          <h3>Existing Top-up Codes</h3>
          {isLoading ? (
            <p>Loading top-up codes...</p>
          ) : topUpCodes.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <Th>Code</Th>
                  <Th>Valid From</Th>
                  <Th>Valid To</Th>
                  <Th>Usage Limit</Th>
                  <Th>Amount</Th>
                  <Th>Public</Th>
                  <Th>Entitled Users</Th>
                  <Th>Actions</Th>
                </tr>
              </thead>
              <tbody>
                {topUpCodes.map((code) => (
                  <tr key={code._id}>
                    <Td>{code.code}</Td>
                    <Td>{new Date(code.validFrom).toLocaleString()}</Td>
                    <Td>{new Date(code.validTo).toLocaleString()}</Td>
                    <Td>{code.usageLimit}</Td>
                    <Td>RM{code.amount.toFixed(2)}</Td>
                    <Td>{code.isPublic ? 'Yes' : 'No'}</Td>
                    <Td>
                      {code.isPublic 
                        ? 'All Users' 
                        : code.entitledUsers.map(user => user.email).join(', ')}
                    </Td>
                    <Td>
                      <Button onClick={() => handleDeleteTopUpCode(code._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No top-up codes found.</p>
          )}
        </>
      )}

      {activeTab === 'postPrice' && (
        <>
          <h2>Set Post Price</h2>
          <Form onSubmit={(e) => { e.preventDefault(); handleUpdatePostPrice(); }}>
            <Input
              type="number"
              step="0.01"
              value={postPrice}
              onChange={handlePostPriceChange}
              placeholder="Post Price (RM)"
              required
            />
            <Button type="submit">Update Post Price</Button>
          </Form>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        </>
      )}
    </Container>
  );
}

export default AdminDashboard;