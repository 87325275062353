import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../pages/icons/keboon-writing.png';

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const SignUpContainer = styled.div`
  width: 90%;
  max-width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  background-color: white;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 85%;
    padding: 20px;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f8f0;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 5px;
  color: #2c3e50;
  text-align: center;
`;

const Subtitle = styled.p`
  color: #666;
  margin-bottom: 20px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  width: 90%;
  padding: 10px;
  background-color: #377E62;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #45a049;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const LoginLink = styled.p`
  text-align: center;
  margin-top: 20px;
`;

const Error = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  text-align: center;
  margin-bottom: 10px;
`;

const FailureMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

function SignUpForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [signUpStatus, setSignUpStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const fullUrl = `${window.location.origin}/api/config`;
        console.log('Fetching config from:', fullUrl);
        const response = await axios.get(fullUrl);
        console.log('Config response:', response.data);
        setApiUrl(response.data.backendUrl);
      } catch (error) {
        console.error('Error fetching API config:', error);
        setError('Unable to connect to the server. Please try again later.');
      }
    };

    fetchConfig();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    setSignUpStatus(null);
    console.log('Attempting sign up with:', { email, password: '****' });
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    if (!apiUrl) {
      setError('Server configuration is not available. Please try again later.');
      setIsLoading(false);
      return;
    }

    try {
      console.log('Sending sign up request to:', `${apiUrl}/api/create-user`);
      const response = await axios.post(`${apiUrl}/api/create-user`, { email, password });
      console.log('Sign up response:', response.data);
      
      // Simulate a delay
      await new Promise(resolve => setTimeout(resolve, 1000));

      if (response.data && response.data.message === 'User created successfully') {
        console.log('Sign up successful');
        setSignUpStatus('success');
        setTimeout(() => {
          navigate('/login');
        }, 1500); // Delay navigation to show success message
      } else {
        console.error('Sign up failed: Invalid response from server', response.data);
        setSignUpStatus('failure');
        setError('Sign up failed: Invalid response from server');
      }
    } catch (error) {
      console.error('Sign up error:', error);
      
      // Simulate a delay
      await new Promise(resolve => setTimeout(resolve, 1000));

      setSignUpStatus('failure');
      if (error.response) {
        console.error('Error response:', error.response.data);
        setError(error.response.data.message || 'Sign up failed. Please try again.');
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError('No response from server. Please check your internet connection and try again.');
      } else {
        console.error('Error', error.message);
        setError('An unexpected error occurred. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <SignUpContainer>
        <Link to="/" style={{ textDecoration: 'none' }}>
         <Logo src={logo} alt="Keboon Logo" />
        </Link>
        <Subtitle>Please Sign Up</Subtitle>
        {error && <Error>{error}</Error>}
        {signUpStatus === 'success' && <SuccessMessage>Sign up successful! Redirecting to login...</SuccessMessage>}
        {signUpStatus === 'failure' && <FailureMessage>Sign up failed. Please try again.</FailureMessage>}
        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              type="email"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isLoading}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={isLoading}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              disabled={isLoading}
            />
          </InputWrapper>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Signing Up...' : 'Sign Up'}
          </Button>
        </Form>
        <LoginLink>
          Already have an account?{' '}
          <Link to="/login" style={{ color: 'purple' }}>
            Log in
          </Link>
        </LoginLink>
      </SignUpContainer>
    </PageContainer>
  );
}

export default SignUpForm;